/** @format */

.hideCloseIcon {
  display: none;
}
.showCloseIcon {
  display: block;
}
.DisplayPhoneError {
  border: 2px solid red;
  border-radius: 5px;
}
.hidePhoneError {
  border: 0.1px solid #d5d5d6;
  border-radius: 5px;
}

body > #webpack-dev-server-client-overlay {
  display: none;
}
