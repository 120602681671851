/** @format */

#add-edit-organiations-user {
  #grid-template-areas-users {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'name qualification'
      'mobile email'
      'owner .'
      'admin-roles .'
      'products products'
      'docSign registrationNumber';
  }
  #grid-template-areas-users > :nth-child(7) {
    grid-template-columns: 50%;
    margin-top: -50px;
    max-width: 50%;
  }

  #grid-template-areas-users > :nth-child(8) {
    max-width: 66%;
    margin-top: -50px;
  }
  #grid-template-areas-product-roles {
    .field-array {
      & > label {
        display: none;
      }
    }
  }

  #grid-template-areas-product-roles {
    grid-template-columns: 50% 50%;
    grid-template-areas: 'product-id product-roles';
  }

  #grid-template-areas-users-button {
    color: 'red';
  }
}
